


import '../css/app.scss';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import {StyledEngineProvider} from "@mui/material";


// import images
import.meta.glob([
    '../assets/images/**',
    '../assets/fonts/**',
]);
import axios, {AxiosInstance} from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Set moment-js language
import moment from "moment";
import 'moment/dist/locale/nl';
moment.locale('nl');






// Extend window with custom attributes
declare global {
    interface Window {
        axios: AxiosInstance,
        DateTime: any // Fix luxon datetime for tabulator
    }
}


const appName = import.meta.env.VITE_APP_NAME || 'Laravel';



createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({ el, App, props }) {
        const root = createRoot(el);
      root.render(<StyledEngineProvider injectFirst><App {...props} /></StyledEngineProvider>);
    },
    progress: {
        color: '#4B5563',
    },
});
