import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useForm} from "@inertiajs/react";
import {relativeRoute} from "@/Helpers/relativeRoute";
import Page from "@/Pages/Page";

export default function Login() {

  type Form = {
    email: string,
    password: string,
    remember_me: boolean,
  }
  const { data, setData, isDirty,  post, processing, errors, setError, clearErrors, reset } = useForm<Form>({
    email: '',
    password: '',
    remember_me: false,
  });


  const handleSubmit = (event) => {
    event.preventDefault();
    post(relativeRoute('login'))
  };

  return <Page title={"Login"}>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Inloggen als beheerder
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            label="Email adres"
            name="email"
            autoComplete="email"
            autoFocus
            value={data.email}
            onChange={(e) => setData('email', e.target.value)}
            error={errors.hasOwnProperty('email')}
            helperText={errors.email}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Wachtwoord"
            type="password"
            autoComplete="current-password"
            value={data.password}
            onChange={(e) => setData('password', e.target.value)}
            error={errors.hasOwnProperty('password')}
            helperText={errors.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Onthoudt mij"
            checked={data.remember_me}
            // @ts-ignore
            onChange={(e) => setData('remember_me', e.target.checked)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Inloggen
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={relativeRoute('password.request')} variant="body2">
                Wachtwoord vergeten?
              </Link>

              <br />
              <br />

              <Typography component="p" variant="body2" align={'left'}>
              Uitvaartverzorger?<br />
              <Link href={relativeRoute('frontend_login')}> Log dan hier in </Link>
            </Typography>

            </Grid>



          </Grid>
        </Box>
      </Box>
    </Container>
  </Page>;
}
