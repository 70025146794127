import {ParameterValue, RouteName, RouteParams} from "ziggy-js";
// import StorybookWindow from "@/types/StorybookWindow";

// Called with a route name and optional additional arguments - returns a URL string
// import {Config, ParameterValue, RouteName, RouteParams, Router} from "ziggy-js";
// export function route(): Router;
// export function route<T extends RouteName>(
//     name: T,
//     params?: RouteParams<T> | undefined,
//     absolute?: boolean,
//     config?: Config,
// ): string;
// export function route<T extends RouteName>(
//     name: T,
//     params?: ParameterValue | undefined,
//     absolute?: boolean,
//     config?: Config,
// ): string;




export function relativeRoute<T extends RouteName>(
  name: URL | string,
  params?: RouteParams<T> | ParameterValue | undefined,
): string {
  // const win = window as StorybookWindow
  // if (win.IS_STORYBOOK){
  //   return 'dummy-route';
  // }
  if (params ){
    // debugger
  }

  // @ts-ignore
  return route(name, params, false)
}


