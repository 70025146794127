import Head from "@/Molecules/Inertia/Head";
import Providers from "@/Providers";
import {usePage} from "@inertiajs/react";
import React, {useEffect} from "react";
import Layout from "@/Organisms/Layout/Layout";
import {CssBaseline} from "@mui/material";
// import StorybookWindow from "@/Types/StorybookWindow";
// import {CommercialPageType} from "@/Atoms/Commercial";
// import useKaChing from "@/Helpers/useKaChing";


export default ({children, title}: {
  children: any,
  title?: string,
}) => {


  // const win = window as StorybookWindow

  // const useSafePage = (win.IS_STORYBOOK)
  //   ? {url: 'dummy-url', component: null}
  //   : usePage();

  const {url, component} = usePage();


  return  <>
    <CssBaseline />
    {title && <Head title={title}/>}
        {children}
  </>
}
