import {Head as InertiaHead} from '@inertiajs/react';
import React from "react";
// import StorybookWindow from "@/types/StorybookWindow";

// copy from Inertiajs Head.d.ts
type InertiaHeadProps = {
    title?: string;
    children?: React.ReactNode;
};


const Head = ({title, children}: InertiaHeadProps) => {

    // const win = window as StorybookWindow

    // if (win.IS_STORYBOOK){
    //     return <></>;
    // }
    return <InertiaHead title={title} children={children} />
}
export default Head;
